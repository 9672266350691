import React, { useState } from "react";
import { Box, Button, IconButton, Modal, SxProps, TextField, Theme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

const style: SxProps<Theme>  = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderRadius: "10px"
};

interface AddNoteModalProps {
  open: boolean;
  onClose: () => void;
  onAddNote: (note: string) => void;
}

const AddNoteModal: React.FC<AddNoteModalProps> = ({
  open,
  onClose,
  onAddNote,
}) => {
  const { t } = useTranslation();

  const [note, setNote] = useState("");

  const handleAddNote = () => {
    onAddNote(note);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon />
        </IconButton>
        <TextField
        sx={{mt: 3}}
          placeholder={t("Note")}
          multiline
          rows={4}
          value={note}
          onChange={(e) => setNote(e.target.value)}
          variant="outlined"
          fullWidth
        />
        <Button
          variant="contained"
          onClick={handleAddNote}
          sx={{ mt: 2, width: "100%", bgcolor: "black", color: "white" }}
        >
          {t("Add Note")}
        </Button>
      </Box>
    </Modal>
  );
};

export default AddNoteModal;
