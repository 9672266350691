import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const useLanguage = () => {
  const { i18n } = useTranslation();

  const changeLanguage = useCallback((language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('i18nextLng', language); // Store the selected language
    // window.location.reload();
  }, [i18n]);

  return {
    changeLanguage,
    currentLanguage: i18n.language,
  };
};

export default useLanguage;