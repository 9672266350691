import { createTheme, Theme } from '@mui/material/styles';
import { useEffect } from 'react';
import { lightTheme } from './light.theme';

const useDirection = (language: string): 'rtl' | 'ltr' => {
  useEffect(() => {
    document.body.dir = language === 'ar' ? 'rtl' : 'ltr';
  }, [language]);

  return language === 'ar' ? 'rtl' : 'ltr';
};

const getThemeWithDirection = (direction: 'rtl' | 'ltr'): Theme =>
  createTheme({
    ...lightTheme,
    direction,
  });

export { useDirection, getThemeWithDirection };
