import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useLanguage, useLoginHook } from "hooks";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { loginSuccess } from "slices";
import restaurantBG from "../assets/restaurant-BGPic.jpg";

type LoginPageProps = PropsWithChildren;

const LoginPage: React.FC<LoginPageProps> = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutateAsync, isError, error, isPending, data } = useLoginHook();
  const dispatch = useDispatch();
  const { changeLanguage } = useLanguage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  useEffect(() => {
    if (data && data.data) {
      dispatch(loginSuccess(data));
      changeLanguage(data.data.locale || "ar")
      navigate("/");
    }
    if (isError) {
      toast.error(error.message);
    }
  }, [data, isError, error, navigate, dispatch]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    await mutateAsync({ email, password });
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${restaurantBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          padding: 4,
          borderRadius: 2,
          boxShadow: 3,
          maxWidth: 400,
          width: "100%",
        }}
      >
        <Typography variant="h4" gutterBottom>
          {t("login.title")}
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            placeholder={t("login.email")}
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            fullWidth
            placeholder={t("login.password")}
            type="password"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            disabled={isPending || !email || !password}
          >
            {isPending ? <CircularProgress size={24} /> : t("login.submit")}
          </Button>
          {isError && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              {error.message}
            </Typography>
          )}
        </form>
      </Box>
    </Box>
  );
};

export default LoginPage;
