export const drawerWidth = 280;

export const localStorageFields = {
  currentBranch: "currentBranch",
  token: "token",
  i18nextLng: "i18nextLng",
};

export const clearLocalStorageFields = () => {
  Object.values(localStorageFields).forEach((field) => {
    localStorage.removeItem(field);
  });
};
