import { ICategory, GenericApiResponse } from "models";
import { axiosInterceptor, mapAxiosError } from "utils";

export const findCategoriesApi = async (branchId?: number) => {
  try {
    const response = await axiosInterceptor.get<GenericApiResponse<ICategory[]>>(
      "v1/categories",
      {
        params: { branchId: branchId },
      }
    );
    return response.data;
  } catch (error: any) {
    throw mapAxiosError(error);
  }
};
