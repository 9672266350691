import { Box, Card } from "@mui/material";
import React, { useState } from "react";
import restaurantBG from "../../assets/no-image.svg";
import { NumericKeypadModal } from "components/ui";
import { IMenuItem } from "models";

interface NewOrderCardComponentProps extends IMenuItem {}

const NewOrderCardComponent: React.FC<NewOrderCardComponentProps> = ({
id,
name,
color,
image,
allowDecimal,
price,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleAdd = (value: number) => {
    console.log("Added value:", value);
    // Handle the added value here
  };
  return (
    <React.Fragment>
      <Card
        onClick={handleOpen}
        sx={{
          padding: "10px",
          borderRadius: "12px",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: color,
          minWidth: "90%",
          maxWidth: "90%",
          minHeight: "90%",
          maxHeight: "90%",
        }}
      >
        <Box
          component="img"
          sx={{
            width: "100%", // Take all available width
            height: "auto", // Maintain aspect ratio
            maxHeight: "150px", // Set a max height for the image
            objectFit: "cover", // Ensure the image covers the container
            display: "block", // Ensure the image is treated as a block element
            margin: "auto", // Center the image horizontally
          }}
          alt={image ? `Image of ${name}` : "Default restaurant image"}
          src={image || restaurantBG}
          aria-label={`Image`}
        />
        <Box
          sx={{ fontWeight: "bold", px: "10px", mt: "10px", fontSize: "20px" }}
        >
          {name}
        </Box>
        <Box
          sx={{ fontWeight: "bold", px: "10px", mt: "10px", fontSize: "18px" }}
        >
          {price}
        </Box>
      </Card>
      <NumericKeypadModal
        open={modalOpen}
        onClose={handleClose}
        onAdd={handleAdd}
      />
    </React.Fragment>
  );
};

export default NewOrderCardComponent;
