import { useMutation, useQuery } from "@tanstack/react-query";
import { loginApi, logOutApi } from "apis";
import { checkTokenApi } from "apis/auth.api";
import { IAuthResponse, ILoginBody } from "models";

export const useLoginHook = () => {
  return useMutation<IAuthResponse, Error, ILoginBody>({
    mutationFn: async (body) => await loginApi(body),
  });
};

export const useLogOutHook = () => {
  return useMutation<void, Error>({
    mutationFn: async () => await logOutApi(),
  });
};

export const useCheckTokenHook = () => {
  return useQuery({
    queryKey: ["check-token"],
    queryFn: async (): Promise<IAuthResponse> => {
      return await checkTokenApi();
    },
  });
};
