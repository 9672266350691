import { createTheme } from '@mui/material/styles';
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#000000',
      dark: 'blue',
    },
    background: {
      default: '#e1e6ea',
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Roboto', sans-serif",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: '50px',
          minHeight: '50px',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          height: '50px',
          minHeight: '50px',
          '@media (min-width: 600px)': {
            minHeight: '50px',
          },
        },
      },
    },
  },
});
