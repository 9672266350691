import React from "react";
import { Card, Typography, Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface OrderCardComponentProps {
  name: string;
  orderNumber: number;
  status: string;
  time: string;
  total: string;
  orderType: string;
  location?: string;
  statusColor?: string;
}

const OrderCardComponent: React.FC<OrderCardComponentProps> = ({
  name,
  orderNumber,
  status,
  location,
  time,
  total,
  orderType,
  statusColor = "#FFEB3B", // Default color if not provided
}) => {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() =>
        navigate(
          `/new-order?isNew=false&status=${status}&orderNumber=${orderNumber}`
        )
      }
      sx={{
        padding: "16px",
        borderRadius: "12px",
        border: "1px solid rgba(0, 0, 0, 0.2)",
        boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "65%",
        minWidth: "75%",
        maxWidth: "75%",
        minHeight: "220px",
        maxHeight: "220px",
        backgroundColor: "#fff",
        ":hover": {
          cursor: "pointer",
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" fontWeight="bold">
            {name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Order # {orderNumber}
          </Typography>
        </Box>
        <Chip
          label={status}
          sx={{
            backgroundColor: statusColor,
            color: "#000",
            fontWeight: "bold",
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        justifyItems={"center"}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            {orderType}
          </Typography>
          {location && (
            <Typography variant="body2" color="textSecondary">
              {location}
            </Typography>
          )}
        </Box>
        <Typography variant="body2" color="textSecondary">
          {time}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
        sx={{ width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            border: "1px solid #000",
            borderRadius: "8px",
            padding: "8px 16px",
            width: "100%",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Total
          </Typography>
          <Typography variant="h6" fontWeight="bold">
            {total}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default OrderCardComponent;
