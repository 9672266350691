import React, { PropsWithChildren } from "react";
import NavBarComponent from "./navbar.component";
import { Box } from "@mui/material";

interface LayoutComponentProps extends PropsWithChildren {}

const LayoutComponent: React.FC<LayoutComponentProps> = ({ children }) => {
  return (
    <Box sx={{ minHeight: "100vh", backgroundColor: "#FFFFFF" }}>
      <NavBarComponent />
      {children}
    </Box>
  );
};

export default LayoutComponent;
