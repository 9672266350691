import { Button, Container, Typography } from "@mui/material";
import LayoutComponent from "components/ui/layout.component";
import React, { PropsWithChildren } from "react";
type NotFoundPageProps = PropsWithChildren;

const NotFoundPage: React.FC<NotFoundPageProps> = () => {
  return (
    <LayoutComponent>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          textAlign: "center",
        }}
      >
        <Typography variant="h1" component="h1" gutterBottom>
          404
        </Typography>
        <Typography variant="h4" component="h2" gutterBottom>
          Page Not Found
        </Typography>
        <Typography variant="body1" gutterBottom>
          The page you're looking for doesn't exist or has been moved.
        </Typography>
        <Button variant="contained" color="primary">
          Go to Home
        </Button>
      </Container>
    </LayoutComponent>
  );
};

export default NotFoundPage;
