import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { OrderCardComponent } from "components";
import LayoutComponent from "components/ui/layout.component";
import { ordersData } from "mocking-data/orders";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";

// OrderTabs Component
const OrderTabs: React.FC<{
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}> = ({ value, onChange }) => (
  <Tabs
    value={value}
    onChange={onChange}
    centered
    sx={{ marginBottom: "16px" }}
  >
    <Tab label="All" />
    <Tab label="In Process" />
    <Tab label="Completed" />
    <Tab label="Cancelled" />
  </Tabs>
);

// Main Orders Page
const OrdersPage: React.FC = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState<string | number>(16);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setItemsPerPage(event.target.value);
  };
  const { t } = useTranslation();

  return (
    <LayoutComponent>
      <Box
        sx={{
          paddingX: "16px",
          height: "calc(100vh - 70px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <OrderTabs value={tabIndex} onChange={handleTabChange} />
          <Button
            variant="contained"
            sx={{
              background: "black",
              color: "white",
              textAlign: "center",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                marginTop: "16px",
              },
            }}
          >
            {t("Add New Order")}
          </Button>
        </Box>
        <SimpleBar style={{ maxHeight: "calc(100vh - 240px)" , minHeight: "calc(100vh - 240px)" }}>
          <Box
            sx={{
              // height: "calc(100vh - 260px)", // Adjust height as needed
              // overflowY: "auto",
              overflowX: "hidden",
              marginBottom: "16px",
              maxWidth: "100%",
            }}
          >
            <Grid
              container
              spacing={3}
              alignItems="start"
              justifyContent="start"
            >
              {ordersData.map((order, index) => (
                <Grid
                  item
                  xs="auto"
                  sm={6}
                  md={4}
                  lg={3}
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <OrderCardComponent {...order} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </SimpleBar>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            gap: "16px", // Space between items
            paddingX: "16px",
          }}
        >
          <FormControl
            variant="outlined"
            size="small"
            sx={{ minWidth: isMobile ? "100%" : 160 }}
          >
            <Select
              value={itemsPerPage.toString()}
              onChange={handleChange}
              displayEmpty
              sx={{
                backgroundColor: "#000", // Background color
                color: "#fff", // Text color
                "& .MuiSelect-icon": {
                  color: "#fff", // Arrow color
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#333", // Border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Border color on hover
                },
              }}
            >
              <MenuItem value={8}>8 per page</MenuItem>
              <MenuItem value={16}>16 per page</MenuItem>
              <MenuItem value={32}>32 per page</MenuItem>
              <MenuItem value={64}>64 per page</MenuItem>
            </Select>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              width: "100%",
              justifyContent: isMobile ? "center" : "end",
              gap: "16px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                textAlign: "center",
                width: isMobile ? "100%" : "150px",
                [theme.breakpoints.down("sm")]: {
                  marginTop: "16px",
                },
              }}
            >
                              {t("Previous")}

            </Button>
            <Button
              variant="contained"
              sx={{
                background: "black",
                color: "white",
                textAlign: "center",
                width: isMobile ? "100%" : "150px",
                [theme.breakpoints.down("sm")]: {
                  marginTop: "16px",
                },
              }}
            >
                              {t("Next")}

            </Button>
          </Box>
        </Box>
      </Box>
    </LayoutComponent>
  );
};

export default OrdersPage;
