import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Language, Logout, Menu } from "@mui/icons-material";
import { useLanguage, useLogOutHook } from "hooks";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logOut, removeBranches, removeCategories } from "slices";
import { clearLocalStorageFields } from "utils";

const NavBarComponent: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activePath, setActivePath] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    setActivePath(location.pathname); // Set the current active path
    console.log("Current path is: ", location.pathname);
  }, [location]);

  const { changeLanguage, currentLanguage } = useLanguage();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const navBarStyles = {
    backgroundColor: "#1a1a1a",
    color: "#fff",
    padding: "16px",
    borderRadius: "5px",
  };

  const drawerContent = (
    <Box
      sx={{
        width: 250,
        ...navBarStyles,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <Button component={Link} to="/" sx={{ color: "#fff", textAlign: "left" }}>
        {t("Home")}
      </Button>
      <Button
        component={Link}
        to="/orders"
        variant={activePath === "/orders" ? "contained" : "text"}
        sx={{ color: "#fff", textAlign: "left" }}
      >
        {t("Orders")}
      </Button>
      <Button
        component={Link}
        to="/new-order"
        variant={activePath === "/new-order" ? "contained" : "text"}
        sx={{ color: "#fff", textAlign: "left" }}
      >
        {t("New Order")}
      </Button>
      <Button
        component={Link}
        to="/kitchen"
        variant={activePath === "/kitchen" ? "contained" : "text"}
        sx={{ color: "#fff", textAlign: "left" }}
      >
        {t("Kitchen")}
      </Button>
      <Button
        onClick={() => console.log("logout")}
        startIcon={<Logout />}
        sx={{ color: "#fff", textAlign: "left" }}
      >
        {t("logout")}
      </Button>
      <Button
        onClick={() => changeLanguage(currentLanguage === "en" ? "ar" : "en")}
        startIcon={<Language />}
        sx={{ color: "#fff", textAlign: "left" }}
      >
        {currentLanguage === "en" ? "English" : "العربية"}
      </Button>
    </Box>
  );

  const dispatch = useDispatch();

  const { mutateAsync } = useLogOutHook();

  const handleLogout = async () => {
    await mutateAsync();
    clearLocalStorageFields();
    dispatch(logOut());
    dispatch(removeBranches());
    dispatch(removeCategories());
    navigate("/login");
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {isMobile && (
        <IconButton onClick={toggleDrawer} sx={{ color: "#000" }}>
          <Menu />
        </IconButton>
      )}
      <Drawer
        color="black"
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
      >
        {drawerContent}
      </Drawer>
      {!isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...navBarStyles,
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", gap: "16px" }}>
            <Button component={Link} to="/" sx={{ color: "#fff" }}>
              {t("Home")}
            </Button>
            <Button
              component={Link}
              to="/orders"
              variant={activePath === "/orders" ? "contained" : "text"}
              sx={{ color: "#fff" }}
            >
              {t("Orders")}
            </Button>
            <Button
              component={Link}
              to="/new-order?isNew=true"
              variant={activePath === "/new-order" ? "contained" : "text"}
              sx={{ color: "#fff" }}
            >
              {t("New Order")}
            </Button>
            <Button
              component={Link}
              to="/kitchen"
              variant={activePath === "/kitchen" ? "contained" : "text"}
              sx={{ color: "#fff" }}
            >
              {t("Kitchen")}
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() =>
                changeLanguage(currentLanguage === "en" ? "ar" : "en")
              }
              startIcon={<Language sx={{ mx: 1 }} />}
              sx={{ color: "#fff" }}
            >
              {currentLanguage === "en" ? "English" : "العربية"}
            </Button>
            <Button
              onClick={handleLogout}
              startIcon={<Logout sx={{ mx: 1 }} />}
              sx={{ color: "#fff" }}
            >
              {t("Logout")}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default NavBarComponent;
