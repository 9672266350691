import { AxiosError } from "axios";
import {
  ConflictError,
  ForbiddenError,
  NetworkError,
  NotFoundError,
  UnAuthenticatedError,
} from "models";
import { clearLocalStorageFields } from "./constants";

interface IServerErrors {
  message: string;
}

export const mapAxiosError = (error: AxiosError<IServerErrors>) => {
  if (!error.response) {
    return new NetworkError();
  } else {
    const status = error.response.status;
    const causedError = error.response.data;
    switch (status) {
      case 409:
        return new ConflictError(causedError.message);
      case 401:
        // localStorage.removeItem("persist:root");
        // clearLocalStorageFields();
        // window.location.reload();
        return new UnAuthenticatedError(causedError.message || "not allowed");
      case 403:
        return new ForbiddenError(causedError.message);
      case 404:
        return new NotFoundError(causedError.message);
      default:
        return error;
    }
  }
};
