export const ordersData = [
  {
    name: "Abanoub Essam",
    orderNumber: 1, // Assuming you have an order number
    status: "In Process",
    location: "Ismailia", // Provide location if available, or use a default value
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Delivery",
    statusColor: "#FFEB3B", // Optional: status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
  {
    name: "Peter Nassef",
    orderNumber: 2, // Assuming you have an order number
    status: "Ready",
    time: "4:48 PM",
    total: "649 EGP",
    orderType: "Pickup",
    statusColor: "#4CAF50", // Optional: different status color
  },
];

export const newOrdersData = [
  {
    title: "Basic Plan",
    price: 9.99,
    // imageUrl: "https://via.placeholder.com/150", // Placeholder image URL
    cardColor: "#FFEB3B", // Default color
  },
  {
    title: "Pro Plan",
    price: 19.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#4CAF50", // Custom green color
  },
  {
    title: "Enterprise Plan",
    price: 49.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#2196F3", // Custom blue color
  },
  {
    title: "Premium Plan",
    price: 29.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#F44336", // Custom red color
  },
  {
    title: "Basic Plan",
    price: 9.99,
    imageUrl: "https://via.placeholder.com/150", // Placeholder image URL
    cardColor: "#FFEB3B", // Default color
  },
  {
    title: "Pro Plan",
    price: 19.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#4CAF50", // Custom green color
  },
  {
    title: "Enterprise Plan",
    price: 49.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#2196F3", // Custom blue color
  },
  {
    title: "Premium Plan",
    price: 29.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#F44336", // Custom red color
  },
  {
    title: "Basic Plan",
    price: 9.99,
    imageUrl: "https://via.placeholder.com/150", // Placeholder image URL
    cardColor: "#FFEB3B", // Default color
  },
  {
    title: "Pro Plan",
    price: 19.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#4CAF50", // Custom green color
  },
  {
    title: "Enterprise Plan",
    price: 49.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#2196F3", // Custom blue color
  },
  {
    title: "Premium Plan",
    price: 29.99,
    imageUrl: "https://via.placeholder.com/150",
    cardColor: "#F44336", // Custom red color
  },
];
