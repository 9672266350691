import React from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AppRoutes from 'routes';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'simplebar-react/dist/simplebar.min.css';
const App: React.FC = () => {
  return (
    <>
      <AppRoutes />
      <ReactQueryDevtools initialIsOpen={false} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default App;
