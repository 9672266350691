import { IAuthResponse, ILoginBody } from "models";
import { axiosInterceptor, localStorageFields, mapAxiosError } from "utils";

export const loginApi = async (data: ILoginBody) => {
  try {
    const response = await axiosInterceptor.post<IAuthResponse>(
      "v1/login",
      data
    );
    return response.data;
  } catch (error: any) {
    throw mapAxiosError(error);
  }
};

export const checkTokenApi = async () => {
  try {
    const token = localStorage.getItem(localStorageFields.token);
    const response = await axiosInterceptor.get<IAuthResponse>(
      "v1/check-token",
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error: any) {
    throw mapAxiosError(error);
  }
};

export const logOutApi = async () => {
  try {
    const response = await axiosInterceptor.post<void>("v1/logout");
    return response.data;
  } catch (error: any) {
    throw mapAxiosError(error);
  }
};
