import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericApiResponse, IBranch } from 'models';

interface BranchesState {
  data: GenericApiResponse<IBranch[]> | null;
  expiry: number | null;
}

const initialState: BranchesState = {
  data: null,
  expiry: null,
};

const branchesSlice = createSlice({
  name: 'branches',
  initialState,
  reducers: {
    setBranches: (state, action: PayloadAction<{ data: GenericApiResponse<IBranch[]>; expiry: number }>) => {
      state.data = action.payload.data;
      state.expiry = action.payload.expiry;
    },
    removeBranches: (state) => {
      state.data = null;
      state.expiry = null;
    },
  },
});

export const { setBranches , removeBranches } = branchesSlice.actions;
export default branchesSlice.reducer;