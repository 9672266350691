import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/root-reducer';

interface ThemeState {
  sideBarToggled: boolean;
}

const initialState: ThemeState = {
  sideBarToggled: false,
};

const themeSlice = createSlice({
  name: 'themeSlice',
  initialState,
  reducers: {
    toggleSideBar(state, { payload }: PayloadAction<boolean>) {
      state.sideBarToggled = payload;
    },
  },
});

export const sidebarToggledSelector = (state: RootState) => state.theme.sideBarToggled;

export default themeSlice.reducer;
export const { toggleSideBar } = themeSlice.actions;
