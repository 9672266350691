import axios, {
  AxiosHeaders,
  InternalAxiosRequestConfig,
  RawAxiosRequestHeaders,
} from "axios";
const baseURL = process.env.REACT_APP_BASE_URL;
const axiosInterceptor = axios.create({
  baseURL,
});

interface iConfig extends InternalAxiosRequestConfig {
  headers: RawAxiosRequestHeaders | AxiosHeaders | any;
}
axiosInterceptor.interceptors.request.use(
  (config: iConfig) => {
    const token = localStorage.getItem("token");
    const savedLanguage = localStorage.getItem("i18nextLng") || "en";
    config.headers = {
      ...config.headers,
      version: "1.0",
      platform: "android",
      locale: savedLanguage,
    };
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    console.log("Configs", config);
    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
