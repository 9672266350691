export class NetworkError extends Error {}
export class ConflictError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}
export class ForbiddenError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}
export class UnAuthenticatedError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}
export class NotFoundError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}
export class BadRequestError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}

export class InternalServerError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}

export class ExpectationFailedError extends Error {
  constructor(protected msg: string) {
    super(msg);
  }
}
