import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import { useFindBranchesHook, useFindCategoriesHook } from "hooks";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import restaurantBG from "../assets/restaurant-BGPic.jpg";
import LoadingPage from "./loading.page";
import { localStorageFields } from "utils";
import { useDispatch } from "react-redux";
import { removeCategories } from "slices";

type HomePageProps = PropsWithChildren;

const CustomFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked }) => ({
    margin: theme.spacing(1),
    padding: "12px",
    border: `2px solid ${
      checked ? theme.palette.primary.main : theme.palette.primary.main
    }`,
    borderRadius: "8px",
    width: "120px", // You can adjust the width to your needs
    textAlign: "center",
    backgroundColor: checked ? theme.palette.primary.main : "transparent",
    color: checked ? "black" : "white",
    transition: "all 0.3s ease",
    display: "flex",
    justifyContent: "center", // Center the text horizontally
    alignItems: "center", // Center the text vertically
    cursor: "pointer", // Add a pointer cursor to enhance UX

    "& .MuiRadio-root": {
      display: "none",
    },
  })
);

const HomePage: React.FC<HomePageProps> = () => {
  const currentBranch = localStorage.getItem(localStorageFields.currentBranch);
  const [branchValue, setBranchValue] = useState<string | null>(currentBranch);

  const dispatch = useDispatch();



  const handleBranchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(removeCategories())
    setBranchValue((event.target as HTMLInputElement).value);
    localStorage.setItem(
      localStorageFields.currentBranch,
      (event.target as HTMLInputElement).value
    );
  };
  const { data, isLoading, error, isError } = useFindBranchesHook();

  const { error: categoriesError, refetch } = useFindCategoriesHook(
    Number(currentBranch),
    false
  );
  const navigate = useNavigate();

  const handleGoClick = async () => {
    // localStorage.setItem(localStorageFields.currentBranch, branchValue!);
    console.log("Click in go")
    await refetch();
    navigate("/orders");
  };

  const { t } = useTranslation();

  // const { data: checkTokenData, error: checkTokenError } = useCheckTokenHook();

  // useEffect(() => {
  //   if (checkTokenError) {

  //   }
  // }, [checkTokenError])

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
    if (categoriesError) {
      toast.error(categoriesError.message);
    }
  }, [error, isError, categoriesError]);

  useEffect(() => {
    if (data?.data.length === 1) {
      localStorage.setItem(
        localStorageFields.currentBranch,
        data.data[0].id.toString()
      );
      setBranchValue(data.data[0].id.toString());
      if (branchValue) {
        refetch();
        navigate("/orders");
      }
    }
    // eslint-disable-next-line
  }, [data ,branchValue]);


  if (isLoading) return <LoadingPage />;

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${restaurantBG})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      {/* Gray Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent gray
          zIndex: 0, // Ensure it's behind the Paper component
        }}
      />

      {/* Content */}
      <Paper
        elevation={10}
        sx={{
          padding: "30px",
          maxWidth: "600px",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          borderRadius: "10px",
          textAlign: "center",
          zIndex: 1, // Ensure the content is above the overlay
        }}
      >
        <Box
          sx={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: 1,
          }}
        >
          <svg
            style={{
              maxWidth: "80px",
            }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
            />
          </svg>
        </Box>

        <Typography variant="h5" align="center" sx={{ mb: 3, color: "#fff" }}>
          {t("Choose Branch")}
        </Typography>
        <FormControl component="fieldset">
          <RadioGroup row value={currentBranch} onChange={handleBranchChange}>
            {data?.data.map((branch) => {
              return (
                <CustomFormControlLabel
                  key={branch.name}
                  value={branch.id}
                  control={<Radio />}
                  label={branch.name}
                  checked={currentBranch === branch.id.toString()}
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        <Box display="flex" justifyContent="space-around" sx={{ mt: 4 }}>
          {
            <Button
            sx={{
              width: "150px",
              height: "50px",
            }}
            variant="contained"
            color="success"
            onClick={handleGoClick}
            disabled={!currentBranch}
          >
            {t("Go")}
          </Button>
          }
        </Box>
      </Paper>
    </Box>
  );
};

export default HomePage;
