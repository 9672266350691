import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Backspace } from "@mui/icons-material";

const style: SxProps<Theme> = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "#333", // Dark background color
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  borderTopLeftRadius: "10px",
  borderBottomLeftRadius: "10px",
};

interface NumericKeypadModalProps {
  open: boolean;
  onClose: () => void;
  onAdd: (value: number) => void;
}

const NumericKeypadModal: React.FC<NumericKeypadModalProps> = ({
  open,
  onClose,
  onAdd,
}) => {
  const [inputValue, setInputValue] = useState<string>("0");

  const handleButtonClick = (value: string) => {
    if (value === "x") {
      setInputValue(inputValue.slice(0, -1) || "0");
    } else {
      setInputValue((prev) => (prev === "0" ? value : prev + value));
    }
  };

  const handleAdd = () => {
    const numericValue = parseInt(inputValue, 10);
    if (numericValue > 0) {
      onAdd(numericValue);
      onClose();
    } else {
      alert("Please enter a value greater than 0");
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" color="white">
          Enter Here
        </Typography>
        <Typography variant="h4" component="div" sx={{ my: 2 }} color="white">
          {inputValue}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 1,
          }}
        >
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
            <Button
              key={num}
              variant="contained"
              onClick={() => handleButtonClick(num.toString())}
              sx={{ width: 60, height: 60, bgcolor: "white", color: "black" }}
            >
              {num}
            </Button>
          ))}

          <Backspace
            onClick={() => handleButtonClick("x")}
            sx={{
              width: 65,
              height: 55,
              color: "white",
              ":hover": {
                cursor: "pointer",
              },
            }}
          />
          <Button
            variant="contained"
            onClick={() => handleButtonClick("0")}
            sx={{ width: 60, height: 60, bgcolor: "white", color: "black" }}
          >
            {0}
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={handleAdd}
          sx={{ mt: 2, width: "100%", bgcolor: "white", color: "black" }}
        >
          Add
        </Button>
      </Box>
    </Modal>
  );
};

export default NumericKeypadModal;
