import { GenericApiResponse, IBranch } from "models";
import { axiosInterceptor, mapAxiosError } from "utils";

export const findBranchesApi = async () => {
  try {
    const response = await axiosInterceptor.get<GenericApiResponse<IBranch[]>>("v1/branches");
    return response.data;
  } catch (error: any) {
    throw mapAxiosError(error);
  }
};
