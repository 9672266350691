import { faAdd, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  AddNoteModal,
  AllCategoriesComponent,
  NewOrderCardComponent,
  PaymentSummaryComponent,
} from "components";
import LayoutComponent from "components/ui/layout.component";
import { useFindCategoriesHook } from "hooks";
import { ICategory } from "models";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { localStorageFields } from "utils";

// NewOrderTabs Component
const NewOrderTabs: React.FC<{
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  categories: ICategory[];
}> = ({ value, onChange, categories }) => (
  <Tabs
    value={value}
    onChange={onChange}
    centered
    sx={{ marginBottom: "16px" }}
  >
    {categories.map((category, index) => (
      <Tab key={category.id} label={category.name} />
    ))}
  </Tabs>
);

// Main Orders Page
const NewOrdersPage: React.FC = () => {
  const location = useLocation();
  console.log(location.search);
  const parsed = queryString.parse(location.search);
  console.log("Parsed query string:", parsed);

  const [tabIndex, setTabIndex] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState<string | number>(16);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setItemsPerPage(event.target.value);
  };

  const [modalOpen, setModalOpen] = React.useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

  const handleOpenAddNoteModal = () => setIsAddNoteModalOpen(true);
  const handleCloseAddNoteModal = () => setIsAddNoteModalOpen(false);

  const handleAddNote = (note: string) => {
    console.log("Added note:", note);
    // Handle the added note here
  };

  const { t } = useTranslation();
  const currentBranch = localStorage.getItem(localStorageFields.currentBranch)
    ? Number(localStorage.getItem(localStorageFields.currentBranch))
    : undefined;
  const { data, error, isError } = useFindCategoriesHook(currentBranch , true);

  useEffect(() => {
    if (isError) {
      toast.error(error.message);
    }
  }, [isError, error]);

  const categories = data?.data || [];

  const selectedCategoryItems = categories[tabIndex]?.menuItems || [];

  const handleCategorySelect = (category: ICategory) => {
    const newIndex = categories.findIndex((cat) => cat.id === category.id);
    if (newIndex !== -1) {
      setTabIndex(newIndex);
      setModalOpen(false);
    }
  };

  return (
    <LayoutComponent>
      <Box
        sx={{
          display: "flex",
          height: "calc(100vh - 70px)",
        }}
      >
        {/* left side box */}
        <Box
          sx={{
            width: isMobile ? "100%" : "20%", // Adjust width for mobile
            margin: "16px",
            padding: "16px",
            borderRadius: "12px",
            border: "1px solid rgba(0, 0, 0, 0.2)",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#fff",
            overflowY: "auto", // Enable scrolling for overflow
          }}
        >
          {/* order */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "3px",
            }}
          >
            <Typography sx={{ fontSize: "1.1rem" }}>Order #1234</Typography>
            <Typography sx={{ fontSize: "1.1rem" }}>3:00 PM</Typography>
          </Box>
          {/* table */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "3px",
            }}
          >
            <Typography sx={{ fontSize: "1.1rem", display: "inline" }}>
              Table No.19 -{" "}
              <Typography
                sx={{
                  fontSize: "1.1rem",
                  display: "inline",
                  fontWeight: "bold",
                }}
                color={"primary"}
              >
                Dine In
              </Typography>{" "}
            </Typography>
            <Typography sx={{ fontSize: "1.1rem" }}>
              <FontAwesomeIcon color="gray" icon={faPenToSquare} />
            </Typography>
          </Box>
          {/* notes */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "3px",
            }}
          >
            <Typography sx={{ fontSize: "1.1rem" }}>
              {t("Add General Notes")}
            </Typography>
            <Typography
              onClick={handleOpenAddNoteModal}
              sx={{
                fontSize: "1.2rem",
                color: "primary",
                ":hover": { cursor: "pointer" },
              }}
            >
              <FontAwesomeIcon icon={faAdd} color="#FF9800" />
            </Typography>
          </Box>
          {/* items box */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Typography sx={{ display: "inline" }}>{t("Items")}</Typography>
              <Box sx={{ display: "inline", mx: "8px" }}></Box>
              <Typography
                sx={{
                  display: "inline",
                  backgroundColor: "#FF990082",
                  paddingY: "1px",
                  paddingX: "8px",
                  borderRadius: "16px",
                }}
              >
                0
              </Typography>
            </Box>
            {/* items list  */}
            <Box
              sx={{
                height: "19rem",
                width: "90%",
                minHeight: "19rem",
                maxHeight: "19rem",
                margin: "16px",
                padding: "16px",
                borderRadius: "12px",
                border: "1px solid rgba(0, 0, 0, 0.2)",
                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#fff",
                overflowY: "auto",
              }}
            >
              {/* <Box>
                list of items
              </Box> */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  color: "#494949",
                }}
              >
                {t("Empty Till Now")}
              </Box>
            </Box>
          </Box>
          {/* payment summary */}
          <PaymentSummaryComponent />
          {/* Buttons */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              width: "100%",
              justifyContent: isMobile ? "center" : "end",
              gap: "16px",
              mt: "4px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                textAlign: "center",
                width: isMobile ? "100%" : "50%",
                height: "40px",
                whiteSpace: "nowrap",
                textTransform: "none",
              }}
            >
              Split Order
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "white",
                color: "black",
                textAlign: "center",
                width: isMobile ? "100%" : "50%",
                whiteSpace: "nowrap",
                textTransform: "none",
                height: "40px",
              }}
            >
              Print Receipt
            </Button>
          </Box>
          <Box sx={{ mt: "10px" }}>
            <Button
              variant="contained"
              sx={{
                background: "black",
                color: "white",
                textAlign: "center",
                width: "100%",
                whiteSpace: "nowrap",
                textTransform: "none",
                height: "40px",
              }}
            >
              Pay Now
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            padding: "8px",
            width: isMobile ? "100%" : "75%", // Adjust width for mobile
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <NewOrderTabs
              value={tabIndex}
              onChange={handleTabChange}
              categories={data?.data || []}
            />
            <Button
              onClick={handleOpen}
              variant="contained"
              sx={{
                background: "black",
                color: "white",
                textAlign: "center",
                [theme.breakpoints.down("sm")]: {
                  width: "100%",
                  marginTop: "16px",
                },
              }}
            >
              {t("View All")}
            </Button>
          </Box>
          <SimpleBar
            style={{
              maxHeight: "calc(100vh - 240px)",
              minHeight: "calc(100vh - 240px)",
              overflowY: "auto", // Ensure vertical scrolling
            }}
          >
            <Box
              sx={{
                // height: "calc(100vh - 260px)", // Adjust height as needed
                // overflowY: "auto",
                overflowX: "hidden",
                marginBottom: "16px",
                maxWidth: "100%",
              }}
            >
              <Grid
                container
                spacing={3}
                alignItems="start"
                justifyContent="start"
              >
                {selectedCategoryItems.map((order, index) => (
                  <Grid
                    item
                    xs="auto"
                    sm={6}
                    md={4}
                    lg={3}
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <NewOrderCardComponent {...order} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </SimpleBar>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
              gap: "16px", // Space between items
              mt: "16px", // Add margin for spacing
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              sx={{ minWidth: isMobile ? "100%" : 160 }}
            >
              <Select
                value={itemsPerPage.toString()}
                onChange={handleChange}
                displayEmpty
                sx={{
                  backgroundColor: "#000", // Background color
                  color: "#fff", // Text color
                  "& .MuiSelect-icon": {
                    color: "#fff", // Arrow color
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#333", // Border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff", // Border color on hover
                  },
                }}
              >
                <MenuItem value={8}>8 per page</MenuItem>
                <MenuItem value={16}>16 per page</MenuItem>
                <MenuItem value={32}>32 per page</MenuItem>
                <MenuItem value={64}>64 per page</MenuItem>
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                width: "100%",
                justifyContent: isMobile ? "center" : "end",
                gap: "16px",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "white",
                  color: "black",
                  textAlign: "center",
                  width: isMobile ? "100%" : "150px",
                  [theme.breakpoints.down("sm")]: {
                    marginTop: "16px",
                  },
                }}
              >
                {t("Previous")}
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: "black",
                  color: "white",
                  textAlign: "center",
                  width: isMobile ? "100%" : "150px",
                  [theme.breakpoints.down("sm")]: {
                    marginTop: "16px",
                  },
                }}
              >
                {t("Next")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <AllCategoriesComponent
        title={t("All Categories")}
        open={modalOpen}
        onClose={handleClose}
        categories={data?.data || []} // Pass the dummy data here
        onCategorySelect={handleCategorySelect} // Pass the handler
      />
      <AddNoteModal
        open={isAddNoteModalOpen}
        onClose={handleCloseAddNoteModal}
        onAddNote={handleAddNote}
      />
    </LayoutComponent>
  );
};

export default NewOrdersPage;
