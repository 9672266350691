import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store/root-reducer";

interface AuthState {
  principle: any | null;
}

const initialState: AuthState = {
  principle: null,
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    loginSuccess(state, { payload }: PayloadAction<any>) {
      state.principle = payload;
      localStorage.setItem("token", payload.data.token); // Store the selected language
    },
    logOut(state) {
      localStorage.removeItem("token");
      state.principle = null;
    },
  },
});

export const principleSelector = (state: RootState) => state.auth.principle;
export const currentUserSelector = (state: RootState) =>
  state.auth.principle?.data;
export const tokenSelector = (state: RootState) =>
  state.auth.principle?.data.token;

export default authSlice.reducer;
export const { loginSuccess, logOut } = authSlice.actions;
