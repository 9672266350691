import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GenericApiResponse, ICategory } from "models";

interface CategoriesState {
  data: GenericApiResponse<ICategory[]> | null;
  expiry: number | null;
}

const initialState: CategoriesState = {
  data: null,
  expiry: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategories: (
      state,
      action: PayloadAction<{
        data: GenericApiResponse<ICategory[]>;
        expiry: number;
      }>
    ) => {
      state.data = action.payload.data;
      state.expiry = action.payload.expiry;
    },
    removeCategories: (state) => {
      state.data = null;
      state.expiry = null;
    },
  },
});

export const { setCategories, removeCategories } = categoriesSlice.actions;
export default categoriesSlice.reducer;
