import { createTheme } from "@mui/material/styles";

// Custom light theme
export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#FF9800", // Custom primary color
    },
    secondary: {
      main: "#4CAF50", // Custom secondary color
    },
    background: {
      default: "#212121", // Background color
    },
  },
  typography: {
    fontFamily: "Almarai, sans-serif", // Custom font
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Customize button border radius
          ":hover": {
            backgroundColor: "#4D4D4D", // Customize button hover background color
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "20px", // Customize Paper padding
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: "16px", // Custom margin for Tabs
        },
        indicator: {
          backgroundColor: "#000000", // Custom color for the tab indicator
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#000", // Default color for all tabs
          fontWeight: "normal", // Regular font weight for non-selected tabs
          '&.Mui-selected': {
            color: "#000000", // Custom color for selected tab
            fontWeight: "bold", // Bold font weight for the selected tab
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small screens
      sm: 600, // Small screens (tablets)
      md: 960, // Medium screens (small laptops)
      lg: 1280, // Large screens (desktops)
      xl: 1920, // Extra large screens (large desktops)
    },
  },
});
