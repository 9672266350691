import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { findBranchesApi } from "apis";
import { GenericApiResponse, IBranch } from "models";
import { RootState } from "store/root-reducer";
import { setBranches } from "slices";
import { localStorageFields } from "utils";

export const useFindBranchesHook = () => {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.branches);

  return useQuery({
    queryKey: ["branches", localStorage.getItem(localStorageFields.token)],
    queryFn: async (): Promise<GenericApiResponse<IBranch[]>> => {
      const now = Date.now();

      if (branches.data && branches.expiry && branches.expiry > now) {
        // Return cached data if it's still valid
        return branches.data;
      }

      // Fetch new data if cache is expired or not present
      const data = await findBranchesApi();
      dispatch(setBranches({ data, expiry: now + 7 * 24 * 60 * 60 * 1000 })); // Cache for 10 minutes
      return data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};