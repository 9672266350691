import { useCurrentUser } from "hooks";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";

interface ProtectedRouteProps extends PropsWithChildren {}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const currentUser = useCurrentUser();
  if (currentUser) {
    return <>{children}</>;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default ProtectedRoute;
