import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";

interface Item {
  name: string;
  quantity: string;
  image: string;
  note?: string;
  color?: string;
}

interface OrderCardProps {
  status: string;
  orderNumber: number;
  tableNumber: number;
  persons: number;
  createdAt: string;
  startAt: string;
  endAt: string;
  notes: string;
  items: Item[];
  buttonText: string;
  buttonColor:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
}

const OrderCard: React.FC<OrderCardProps> = ({
  status,
  orderNumber,
  tableNumber,
  persons,
  createdAt,
  startAt,
  endAt,
  notes,
  items,
  buttonText,
  buttonColor,
}) => (
  <Card
    sx={{
      width: "33%",
      my: 2,
      height: "40rem",
      boxShadow: 2,
      border: status === "Changed" ? "2px solid #e40000" : "none",
      overflowY: "auto",
    }}
  >
    <CardContent
      sx={{
        p: 3,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          sx={{
            backgroundColor: "#e6a82f33",
            borderRadius: 1,
            p: 2.5,
            mb: 2,
          }}
        >
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Order # {orderNumber}
              </Typography>
              <Typography variant="h6">Dine In</Typography>
              <Typography variant="body2">
                Table <strong>No. {tableNumber}</strong> - {persons} Persons
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor:
                    status === "New Order"
                      ? "#9747ff80"
                      : status === "Preparing"
                      ? "#ffc70080"
                      : "#e4000080",
                  borderRadius: 1,
                  p: 0.5,
                }}
              >
                <Typography variant="caption" color="textSecondary">
                  {status}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Grid item>
              <Typography variant="body2">Created at</Typography>
              <Typography variant="body2" fontWeight="medium">
                {createdAt}
              </Typography>
            </Grid>
            <Grid item>
              <Box
                sx={{
                  backgroundColor: "primary.main",
                  borderRadius: 1,
                  p: 0.5,
                }}
              >
                <Typography variant="body2" color="white" fontWeight="bold">
                  01 : 10
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: 2 }}
          >
            <Grid item>
              <Typography variant="body2">Start at</Typography>
              <Typography
                variant="body2"
                fontWeight="medium"
                color="success.main"
              >
                {startAt}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2">End at</Typography>
              <Typography variant="body2" fontWeight="medium">
                -- : --
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 1,
            boxShadow: 1,
            p: 2.5,
            mb: 2,
          }}
        >
          <Typography variant="caption" color="textSecondary">
            General Notes
          </Typography>
          <Typography variant="body2">{notes}</Typography>
        </Box>
        <List>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              <ListItem>
                <img
                  src={item.image}
                  alt={item.name}
                  style={{
                    width: 70,
                    height: 57,
                    objectFit: "cover",
                    marginRight: 16,
                  }}
                />
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      fontWeight="bold"
                      color={item.color}
                    >
                      {item.name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      fontWeight="medium"
                      color={item.color}
                    >
                      {item.quantity}
                    </Typography>
                  }
                />
              </ListItem>
              {item.note && (
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: 1,
                    boxShadow: 1,
                    p: 1,
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <Typography variant="caption" color="textSecondary">
                    {item.note}
                  </Typography>
                </Box>
              )}
              {index < items.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Box>
      <Box sx={{ m: 2, p: 2 }}>
        <Button
          variant="contained"
          color={buttonColor}
          sx={{ width: "100%", height: 70, borderRadius: 1 }}
        >
          <Typography variant="h6" color="white">
            {buttonText}
          </Typography>
        </Button>
      </Box>
    </CardContent>
  </Card>
);

const Frame: React.FC = () => {
  const orders: OrderCardProps[] = [
    {
      status: "New Order",
      orderNumber: 990,
      tableNumber: 19,
      persons: 3,
      createdAt: "04 : 48 PM",
      startAt: "-- : --",
      endAt: "-- : --",
      notes: "Preparing the order at 8:00 PM",
      items: [
        { name: "Jumbo Shrimp", quantity: "2.5", image: "rectangle-37.png" },
        {
          name: "Water",
          quantity: "2 x",
          image: "image.png",
          note: "Without Salt",
        },
        { name: "Fish", quantity: "1", image: "rectangle-37-2.png" },
        { name: "Soups", quantity: "2 x", image: "rectangle-37-3.png" },
      ],
      buttonText: "Start Cooking",
      buttonColor: "success",
    },
    {
      status: "Preparing",
      orderNumber: 990,
      tableNumber: 19,
      persons: 3,
      createdAt: "04 : 48 PM",
      startAt: "04 : 54 PM",
      endAt: "-- : --",
      notes: "Preparing the order at 8:00 PM",
      items: [
        { name: "Jumbo Shrimp", quantity: "2.5", image: "rectangle-37-4.png" },
        {
          name: "Water",
          quantity: "2 x",
          image: "rectangle-37-5.png",
          note: "Without Salt",
        },
        { name: "Fish", quantity: "1", image: "rectangle-37-6.png" },
        { name: "Soups", quantity: "2 x", image: "rectangle-37-7.png" },
      ],
      buttonText: "Finish Cooking",
      buttonColor: "primary",
    },
    {
      status: "Changed",
      orderNumber: 990,
      tableNumber: 19,
      persons: 3,
      createdAt: "04 : 48 PM",
      startAt: "04 : 54 PM",
      endAt: "-- : --",
      notes: "Preparing the order at 8:00 PM",
      items: [
        {
          name: "Jumbo Shrimp",
          quantity: "3.5",
          image: "rectangle-37-8.png",
          color: "error.main",
        },
        {
          name: "Water",
          quantity: "2 x",
          image: "rectangle-37-9.png",
          note: "Without Salt",
        },
        { name: "Fish", quantity: "1", image: "rectangle-37-10.png" },
        { name: "Soups", quantity: "2 x", image: "rectangle-37-11.png" },
      ],
      buttonText: "Start Cooking",
      buttonColor: "success",
    },
  ];

  return (
    <Box display="flex" gap={3}>
      {orders.map((order, index) => (
        <OrderCard key={index} {...order} />
      ))}
    </Box>
  );
};

export default Frame;
