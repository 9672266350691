import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import { store } from "store";
import { ThemeProvider } from "@emotion/react";
import { BrowserRouter } from "react-router-dom";
import { getThemeWithDirection, useDirection } from "themes";
import "./i18n/config"; // Import the i18n configuration
import { useTranslation } from "react-i18next";
import { createGenerateClassName, StylesProvider } from "@mui/styles";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
    },
  },
});
const generateClassName = createGenerateClassName({
  productionPrefix: "c",
});

const MainApp: React.FC = () => {
  const { i18n } = useTranslation();
  const direction = useDirection(i18n.language);
  const theme = getThemeWithDirection(direction);

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ThemeProvider>
        </StylesProvider>
      </QueryClientProvider>
    </Provider>
  );
};

root.render(
  <React.StrictMode>
    <MainApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
