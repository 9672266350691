import { ProtectedRoute } from "components";
import { HomePage, KitchenPage, LoginPage, NotFoundPage, OrdersPage } from "pages";
import NewOrdersPage from "pages/new-orders.page";
import React, { PropsWithChildren } from "react";
import { Route, Routes } from "react-router-dom";
type AppRoutesProps = PropsWithChildren;

const AppRoutes: React.FC<AppRoutesProps> = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/orders"
        element={
          <ProtectedRoute>
            <OrdersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/new-order"
        element={
          <ProtectedRoute>
            <NewOrdersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/kitchen"
        element={
          <ProtectedRoute>
            <KitchenPage />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;
