import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface PaymentSummaryComponentProps {
  subtotal?: string;
  discount?: string;
  tax?: string;
  service?: string;
  totalPrice?: string;
}

const PaymentSummaryComponent: React.FC<PaymentSummaryComponentProps> = ({
  subtotal = "0 EGP",
  discount = "0 EGP",
  service = "0 EGP",
  tax = "0 EGP",
  totalPrice = "0 EGP",
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <Box
        sx={{
          height: "9.5rem",
          width: "90%",
          minHeight: "9.5rem",
          maxHeight: "9.5rem",
          px: "16px",
          py: "15px",
          mb: "3px",
          borderRadius: "12px",
          border: "1px solid rgba(0, 0, 0, 0.2)",
          boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <Typography sx={{ fontSize: "1.1rem", fontWeight: "bold", lineHeight: '0.8rem' }}>
          {t("Payment Summary")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "4px",
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("Subtotal")}:
          </Typography>
          <Typography
            color={"primary"}
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            {subtotal}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "4px",
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("Discount")}:
          </Typography>
          <Typography
            color={"primary"}
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            {discount}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "4px",
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("Tax 14%")}:
          </Typography>
          <Typography
            color={"primary"}
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            {tax}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "4px",
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("Service 12%")}:
          </Typography>
          <Typography
            color={"primary"}
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            {service}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "4px",
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: "bold" }}>
            {t("Total Price")}:
          </Typography>
          <Typography
            color={"primary"}
            sx={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            {totalPrice}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSummaryComponent;
