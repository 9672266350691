import { combineReducers } from "@reduxjs/toolkit";
import { PersistConfig, persistReducer } from "redux-persist";
import { AuthReducer, BranchesReducer, ThemeReducer , CategoriesReducer} from "slices";
import storage from "./storage";

const reducers = combineReducers({
  auth: AuthReducer,
  theme: ThemeReducer,
  branches: BranchesReducer,
  categories: CategoriesReducer
});

export type RootState = ReturnType<typeof reducers>;

const persistConfig: PersistConfig<RootState> = {
  key: "root",
  storage,
  version: 1,
  blacklist: [],
};

export const rootReducer = persistReducer(persistConfig, reducers);
