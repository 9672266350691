import { Box, Modal, Typography, IconButton, Grid, Card } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { ICategory } from "models";
import restaurantBG from "../../assets/no-image.svg";

interface AllCategoriesComponentProps {
  open: boolean;
  onClose: () => void;
  title: string;
  categories: ICategory[];
  style?: React.CSSProperties;
  onCategorySelect: (category: ICategory) => void; // New prop
}

const AllCategoriesComponent: React.FC<AllCategoriesComponentProps> = ({
  open,
  onClose,
  title,
  categories,
  style,
  onCategorySelect, // Destructure the new prop
}) => {
  const defaultStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "80%",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    pt: 2,
    px: 4,
    pb: 3,
    ...style, // Merge custom styles with default styles
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={defaultStyle}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#000",
          }}
        >
          <CloseIcon
            sx={{
              fontSize: 28, // Increase the size to make it more prominent
              fontWeight: "bold", // This won't directly make the icon bold, but it's good to keep for any text-like icons
              color: "#000",
            }}
          />
        </IconButton>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ textAlign: "center", my: 2, fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <Box sx={{ height: "calc(100% - 64px)", overflowY: "auto" , overflowX: "hidden"}}>
          <Grid container spacing={4} justifyContent="center">
            {categories.map((category, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  sx={{
                    borderRadius: "12px",
                    textAlign: "center",
                    padding: "20px",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    width: "70%",
                  }}
                  onClick={() => onCategorySelect(category)} // Add click handler
                >
                  <Box
                    sx={{
                      backgroundColor: category.color,
                      borderRadius: "2px",
                      padding: "20px",
                    }}
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "100%", // Set the width to 100% of the container
                        height: "auto", // Maintain aspect ratio
                        objectFit: "cover",
                        borderRadius: "50%", // Make the image circular
                        marginBottom: "10px",
                        display: "block",
                        margin: "0 auto", // Center the image horizontally
                      }}
                      alt={`Image`}
                      src={category.image || restaurantBG} // Provide a fallback image
                    />
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {category.name}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default AllCategoriesComponent;
