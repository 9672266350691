import { useQuery } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import { findCategoriesApi } from "apis";
import { GenericApiResponse, ICategory } from "models";
import { RootState } from "store/root-reducer";
import { setCategories } from "slices";

export const useFindCategoriesHook = (
  branchId?: number,
  enabled: boolean = false,
  cacheFirst: boolean = true,
) => {
  const dispatch = useDispatch();
  const categories = useSelector((state: RootState) => state.categories);

  return useQuery({
    queryKey: ["categories"],
    queryFn: async (): Promise<GenericApiResponse<ICategory[]>> => {
      const now = Date.now();
      console.log("Categories Fetching")
      if (
        categories.data &&
        categories.expiry &&
        categories.expiry > now &&
        cacheFirst
      ) {
        // Return cached data if it's still valid
        return categories.data;
      }

      // Fetch new data if cache is expired or not present
      const data = await findCategoriesApi(branchId);
      dispatch(setCategories({ data, expiry: now + 7 * 24 * 60 * 60 * 1000 })); // Cache for 10 minutes
      return data;
    },
    staleTime: 5 * 60 * 1000, // 5 minutes
    enabled,
  });
};
